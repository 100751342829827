// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "controllers"
import flatpickr from "flatpickr"
const Polish = require("flatpickr/dist/l10n/pl.js").default.pl;
flatpickr.localize(Polish);

require("flatpickr/dist/flatpickr.css")
require("modernizr/modernizr.custom.js");

global.toastr = require("toastr")
require("@rails/ujs").start()

//= require jquery
//= require parsley
//= require clipboard

//= require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

//--- Bootstrap
import 'bootstrap';
//= link filterrific/filterrific-spinner.gif
import appInit from './angle/app.init.js';
document.addEventListener('DOMContentLoaded', appInit);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

import $ from 'jquery';

import initColorPicker from './angle/modules/forms/color-picker';
import initFormsDemo from './angle/modules/forms/forms';
import initImageCropper from './angle/modules/forms/imagecrop';
import initSelect2 from './angle/modules/forms/select2';
import initDropzone from './angle/modules/forms/upload';
import initWizard from './angle/modules/forms/wizard';
import initXEditable from './angle/modules/forms/xeditable';
import './angle/modules/forms/validation';

$(function() {
  initColorPicker();
  initFormsDemo();
  initImageCropper();
  initSelect2();
  initDropzone();
  initWizard();
  initXEditable();
});

const Clipboard = require("clipboard");

$(document).ready(function(){
  var clipboard = new Clipboard('.clipboard-btn');
});

import { Filterrific } from './filterrific';
global.Filterrific = Filterrific;
