// Xeditable Demo
// -----------------------------------

import $ from 'jquery';
import 'bootstrap/js/dist/popover';

function preInitXEditable() {
    if ($('.x-editable-card').length) {
        // xEditable
        // trick to load xeditable only when used on page and avoid issue with bootstrap-datepicker
        import('x-editable/dist/bootstrap3-editable/css/bootstrap-editable.css').then(() => {
            import('x-editable/dist/bootstrap3-editable/js/bootstrap-editable.min.js').then(() => {
                initXEditable();
            });
        });
    }
}

function initXEditable() {

    if (!$.fn.editable) return

    // Font Awesome support
    $.fn.editableform.buttons =
        '<button type="submit" class="btn btn-primary btn-sm editable-submit">' +
        '<i class="fa fa-fw fa-check"></i>' +
        '</button>' +
        '<button type="button" class="btn btn-default btn-sm editable-cancel">' +
        '<i class="fa fa-fw fa-times"></i>' +
        '</button>';

    //defaults
    //$.fn.editable.defaults.url = 'url/to/server';

    //enable / disable
    $('#enable').click(function() {
        $('#user .editable').editable('toggleDisabled');
    });

    //editables
    $('#username').editable({
        // url: 'url/to/server',
        type: 'text',
        pk: 1,
        name: 'username',
        title: 'Enter username',
        mode: 'inline'
    });

    $('#firstname').editable({
        validate: function(value) {
            if ($.trim(value) === '') return 'This field is required';
        },
        mode: 'inline'
    });

    $('#sex').editable({
        prepend: "not selected",
        source: [
            { value: 1, text: 'Male' },
            { value: 2, text: 'Female' }
        ],
        display: function(value, sourceData) {
            var colors = { "": "gray", 1: "green", 2: "blue" },
                elem = $.grep(sourceData, function(o) { return o.value == value; });

            if (elem.length) {
                $(this).text(elem[0].text).css("color", colors[value]);
            } else {
                $(this).empty();
            }
        },
        mode: 'inline'
    });

    $('#status').editable({
        mode: 'inline'
    });

    $('#group').editable({
        showbuttons: false,
        mode: 'inline'
    });

    $('#dob').editable({
        mode: 'inline'
    });

    $('#event').editable({
        placement: 'right',
        combodate: {
            firstItem: 'name'
        },
        mode: 'inline'
    });

    $('#comments').editable({
        showbuttons: 'bottom',
        mode: 'inline'
    });

    $('#note').editable({
        mode: 'inline'
    });
    $('#pencil').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        $('#note').editable('toggle');
    });

    $('#user .editable').on('hidden', function(e, reason) {
        if (reason === 'save' || reason === 'nochange') {
            var $next = $(this).closest('tr').next().find('.editable');
            if ($('#autoopen').is(':checked')) {
                setTimeout(function() {
                    $next.editable('show');
                }, 300);
            } else {
                $next.focus();
            }
        }
    });

    // TABLE
    // -----------------------------------

    $('#users a').editable({
        type: 'text',
        name: 'username',
        title: 'Enter username',
        mode: 'inline'
    });

}

export default preInitXEditable;
