
import Dropzone from 'dropzone';

// Prevent Dropzone from auto discovering
// This is useful when you want to create the
// Dropzone programmatically later
Dropzone.autoDiscover = false;

function initDropzone() {

    // Dropzone settings
    var dropzoneOptions = {
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 100,
        maxFiles: 100,
        dictDefaultMessage: '*.csv', // default messages before first drop
        paramName: 'file', // The name that will be used to transfer the file
        maxFilesize: 2, // MB
        addRemoveLinks: true,
        accept: function(file, done) {
            console.log(`%c file.type: ` + `%c >> `,'color: orange','color: cyan', [null, '', 'text/csv', 'text/x-csv', 'application/vnd.ms-excel',
                'application/csv', 'application/x-csv', 'text/comma-separated-values',
                'text/x-comma-separated-values'].includes(file.type))

            if ([null, '', 'text/csv', 'text/x-csv', 'application/vnd.ms-excel',
                'application/csv', 'application/x-csv', 'text/comma-separated-values',
                'text/x-comma-separated-values'].includes(file.type) || file.name.includes('.csv')){

                done();
                $('#dropzone-submit').prop( "disabled", false );
                $(".dz-remove").html("<div><span class='fa fa-trash text-danger' style='font-size: 2em; padding-top: 30px;'></span></div>");
            } else {
                done();
                $('#dropzone-submit').prop( "disabled", true );
                toastr.error("Niewłaściwy format pliku!", "Błąd ładowania");
                $(".dz-remove").html("<div><span class='fa fa-trash text-danger' style='font-size: 2em; padding-top: 30px;'></span></div>");
            }
        },
        init: function() {
            var dzHandler = this;
            $('#dropzone-submit').prop( "disabled", true );

            this.element.querySelector('button[type=submit]').addEventListener('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                dzHandler.processQueue();
            });
            this.on('addedfile', function(file) {
                console.log('Added file: ' + file.name);
                $('#dropzone-submit').prop( "disabled", false );
            });
            this.on('removedfile', function(file) {
                console.log('Removed file: ' + file.name);
            });
            this.on('sendingmultiple', function() {

            });
            this.on('successmultiple', function( /*files, response*/ ) {

            });
            this.on('errormultiple', function( /*files, response*/ ) {

            });
            this.on("success", function(e, data, status, xhr) {
                $('#dropzone-submit').prop( "disabled", true );
                $('#dropzone-submit').html( "PRZEŚLIJ KOLEJNY PLIK" );
                $('#dropzone-submit').removeClass( "btn-primary" );
                $('#dropzone-submit').addClass( "btn-outline-primary" );
                toastr.success("Dane zaimportowane!", "Ładowanie zakończone");
                this.removeAllFiles();
                $('#subs-body').find('tr').remove();
                $.each(data.subaccounts, function(i, v) {
                    var id = v.id
                    var user_login = v.user_login
                    var user_email = v.user_email
                    var user_sport = v.user_sport
                    var user_store = v.user_store
                    var subaccount_title = v.subaccount_title
                    var connected_login = v.connected_login
                    var newRowContent = "<tr><td>" + user_login + "</td><td>" + user_email + "</td><td>" + user_sport + "</td><td>" + user_store + "</td><td>" + subaccount_title + "</td><td>" + connected_login + "</td></tr>";
                    $('#subs-body').append(newRowContent);
                });
            });
        }

    };

    if(document.getElementById('dropzone-area'))
        var dropzoneArea = new Dropzone('#dropzone-area', dropzoneOptions);

}

export default initDropzone;
